<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="border">
                    <div class="col-md-12">

                        <div class="camera-options">
                           <span class="cam-option"> <input type="radio" v-model="camera" value="" id="nocamera" checked="checked"><label for="nocamera">Bez foto</label></span>
                            <span v-for="device in devices" class="cam-option" :key="device.deviceId">
                                <input type="radio" v-model="camera" :id="'cam'+device.deviceId" :value="device.deviceId"><label :for="'cam'+device.deviceId">{{device.label}}</label></span>
                        </div>
                    </div>
                    <div :class="getClass()">
                        <vue-web-cam
                            ref="webcam"
                            :device-id="deviceId"
                            width="100%"
                            @started="onStarted"
                            @stopped="onStopped"
                            @error="onError"
                            @cameras="onCameras"
                            @camera-change="onCameraChange"
                    />

                    <div class="col-md-12 capture-holder" v-if="camera">
                        <button type="button" class="capture-button" @click="onCapture">
                            <f7-icon class="float-left camera-icon" f7="camera_viewfinder"></f7-icon></button>
                    </div>
                    </div>
                       </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { WebCam } from "vue-web-cam";
    export default {
        name: "App",
        components: {
            "vue-web-cam": WebCam
        },
        data() {
            return {
                img: null,
                camera: null,
                deviceId: null,
                devices: []
            };
        },
        computed: {
            device: function() {
                return this.devices.find(n => n.deviceId === this.deviceId);
            }
        },
        watch: {
            camera: function(id) {
                this.deviceId = id;
                if(id)
                    navigator.mediaDevices.getUserMedia({ video: true });
            },
            devices: function() {
                // Once we have a list select the first one
                const [first, ...tail] = this.devices;
                if (!first) {
                    this.camera = first.deviceId;
                    this.deviceId = first.deviceId;
                }
            }
        },
        methods: {
            getClass(){
                return {
                    'show cam-parent': this.camera,
                    'noshow cam-parent': !this.camera}
            },
            onCapture() {
                this.img = this.$refs.webcam.capture();

                this.$emit('clicked', this.img);
            },
            onStarted(stream) {
                console.log("On Started Event", stream);
            },
            onStopped(stream) {
                console.log("On Stopped Event", stream);
               this.img = ""; this.$emit('clicked', this.img);
            },
            onStop() {
                this.$refs.webcam.stop();
            },
            onStart() {
                this.$refs.webcam.start();
            },
            onError(error) {
                console.log("On Error Event", error);
            },
            onCameras(cameras) {
                this.devices = cameras;
                console.log("On Cameras Event", cameras);
            },
            onCameraChange(deviceId) {
                this.deviceId = deviceId;
                this.camera = deviceId;

                console.log("On Camera Change Event", deviceId);
            }
        }
    };
</script>
<style scoped>
    video
    {height:350px;text-align:left;}
    select
    {border:1px solid #afafaf; padding-left:30px; width:300px;margin:0 auto;text-align:center}
    select option
    {text-align:center;}

    .photo
    {margin-bottom:30px}
    .capture-holder
    {position:relative; text-align:center}
    .captured-image
    {position:absolute;top:50px;right:0;width:170px;}
    .captured-image figure,
    .captured-image figure img
    {width:100%;padding:0;margin:0;}
    .border
    {position:relative}
    .cam-option
    {display:inline-block;width:100%; font-size: 15px}
    .cam-parent
    {min-height:50px}
    .cam-parent.showed video
    {height:400px;}
    .cam-parent.noshow
    {margin-bottom: 10px}
    .cam-parent.noshow video
    {height:10px; margin-bottom:10px;}
    .capture-button i
    {padding:0;font-size:46px!important;background-color:#fff;border-radius:100%; padding:4px}
    .capture-button
    {width:auto; text-align:center;padding:4px;background-color:rgba(255,255,255,.5);border:0;border-radius:100%; position:relative; top:-100px; float:none;z-index:999;margin:0 auto;}
</style>
