
import HomePage from '../pages/home.vue';
import AboutPage from '../pages/about.vue';
import SettingsPage from '../pages/setup.vue';
import FormPage from '../pages/form.vue';
import Login from '../pages/login.vue';


import DynamicRoutePage from '../pages/dynamic-route.vue';
import RequestAndLoad from '../pages/request-and-load.vue';
import NotFoundPage from '../pages/404.vue';




function MyAuthMiddleware(to, from, resolve,reject) {


  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    this.navigate('/login');
  }
}


var routes = [
  {
    path: '/',
    async(routeTo, routeFrom, resolve, reject) {
      const publicPages = ['/login'];
      const authRequired = !publicPages.includes(routeTo.path);
      const loggedIn = localStorage.getItem('user');
      if (authRequired && !loggedIn) {
        {resolve({
          component: Login,
        });

        }
      } else
      {resolve({
        component: HomePage,
      });
      }
    },
  },
  {
    path: '/home/',
    component: HomePage,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
    {
        path: '/settings/',
        component: SettingsPage,
    },
  {
    path: '/form/',
    component: FormPage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
